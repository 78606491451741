import axios from "axios";
import InvoiceStateDTO from "@/models/InvoiceStateDTO";

export default class APIClient {

    getInvoice = async (invoiceId: string): Promise<InvoiceStateDTO> => {
        let resp = await axios({
            url: `/api/public/invoices/${invoiceId}`,
            method: 'GET',
        });

        return resp.data as InvoiceStateDTO;
    }

    openDispute = async (invoiceId: string, form: FormData): Promise<InvoiceStateDTO> => {
        let resp = await axios({
            url: `/api/public/invoices/${invoiceId}/open-dispute`,
            method: 'POST',
            data: form,
            headers: {
                "Content-Type": "multipart/form-data",
            },
        });

        return resp.data as InvoiceStateDTO;
    }

    setDisputeAttachment = async (invoiceId: string, form: FormData): Promise<InvoiceStateDTO> => {
        let resp = await axios({
            url: `/api/public/invoices/${invoiceId}/dispute-attachment`,
            method: 'POST',
            data: form,
            headers: {
                "Content-Type": "multipart/form-data",
            },
        });

        return resp.data as InvoiceStateDTO;
    }

    getAvailableMethods = async (invoiceId: string): Promise<string[]> => {
        let resp = await axios({
            url: `/api/public/invoices/${invoiceId}/available-methods`,
            method: 'GET',
        })

        return resp.data as string[]
    }

    startDeal = async (invoiceId: string, method: string): Promise<InvoiceStateDTO> => {
        let resp = await axios({
            url: `/api/public/invoices/${invoiceId}/start-deal`,
            data: {
                'paymentMethod': method
            },
            method: 'POST',
        })

        return resp.data as InvoiceStateDTO
    }

    cancelDeal = async (invoiceId: string, dealId: string): Promise<InvoiceStateDTO> => {
        let resp = await axios({
            url: `/api/public/invoices/${invoiceId}/cancel-deal`,
            data: {
                'dealId': dealId
            },
            method: 'POST',
        })

        return resp.data as InvoiceStateDTO
    }

    cancelInvoice = async (invoiceId: string): Promise<InvoiceStateDTO> => {
        let resp = await axios({
            url: `/api/public/invoices/${invoiceId}/cancel`,
            method: 'POST',
        })

        return resp.data as InvoiceStateDTO
    }

    confirmTransfer = async (invoiceId: string, dealId: string): Promise<InvoiceStateDTO> => {
        let resp = await axios({
            url: `/api/public/invoices/${invoiceId}/confirm-transfer`,
            data: {
                'dealId': dealId
            },
            method: 'POST',
        })

        return resp.data as InvoiceStateDTO
    }

}