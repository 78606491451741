import {createRouter, createWebHistory, RouteRecordRaw} from 'vue-router'
import Invoice from '@/views/Invoice.vue'

const routes: Array<RouteRecordRaw> = [
    {path: '/invoice', name: 'invoice', component: Invoice},
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router

