class NumberFormatter {
    public formatMoney(value: number|null, currency: string = 'USD'): string|null {
        if (value === null || isNaN(value)) {
            return null;
        }
        const formatter = new Intl.NumberFormat('ru-RU', {
            style: 'currency',
            currency: currency,
        });

        return formatter.format(value);
    }

    public formatNumber(value: number|null, minimumFractionDigits: number = 0): string|null {
        if (value === null || isNaN(value)) {
            return null;
        }
        const formatter = new Intl.NumberFormat('ru-RU', {
            minimumFractionDigits,
            maximumFractionDigits: minimumFractionDigits
        });

        return formatter.format(value)
    }
}

const numberFormatter = new NumberFormatter();

export {
    numberFormatter
}
